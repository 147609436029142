<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-10 offset-sm-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="lockscreen-logo">
                        <a href="/ujian">
                            <img src="@/assets/img/logo-tosca.png" alt="logo" width="250">
                        </a>
                    </div>
                    <!-- User name -->
                    <div class="text-center" style="padding: 12px;">
                        <img class="img-bordered-sm" :src="info.img_url" alt="user image" style="width:60%; border-radius:20px;">
                    </div>
                    <br>
                    <br>
                    <div class="text-center">
                        <h6>{{info.display_name}}</h6>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="text-center">
                                <p>Kamu telah berhasil menyelesaikan seluruh <b>Tes Kecermatan</b>. Berikut nilai grafik dari hasil tes kamu.</p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>

                    <div class="row w-100">
                        <div class="col-12">
                            <div class="card card-penilaian" v-for="(data, index) in datas">
                                <div class="card-body text-center">
                                    <h5 class="text-bold">Tes Kecermatan {{data.tipe}}</h5>
                                    <table class="table table-bordered mt-4">
                                        <thead>
                                            <tr>
                                                <th>No Kolom</th>
                                                <th>Jumlah Soal</th>
                                                <th>Benar</th>
                                                <th>Salah</th>
                                                <th>Tidak Terjawab</th>
                                                <th>Terjawab</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in data.table_data">
                                                <td>Kolom {{ item.col_index }}</td>
                                                <td>{{ item.jumlah_soal }}</td>
                                                <td>{{ item.jawaban_benar }}</td>
                                                <td>{{ item.jawaban_salah }}</td>
                                                <td>{{ item.tidak_terjawab }}</td>
                                                <td>{{ item.terjawab }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Total</th>
                                                <th>{{ getTotal(data.table_data, 'jumlah_soal') }}</th>
                                                <th>{{ getTotal(data.table_data, 'jawaban_benar') }}</th>
                                                <th>{{ getTotal(data.table_data, 'jawaban_salah') }}</th>
                                                <th>{{ getTotal(data.table_data, 'tidak_terjawab') }}</th>
                                                <th>{{ getTotal(data.table_data, 'terjawab') }}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div class="row mt-4">
                                        <div class="col-sm-8">
                                            <div class="card" style="border: 2px solid #6190E8;">
                                                <div class="card-body">
                                                    <canvas ref="bar-chart" :id="'grouped-chart-' + index" style="display: block; width: 100%; height: 200px;" class="chartjs-render-monitor"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="card" style="border: 2px solid #6190E8;">
                                                <div class="card-body">
                                                    <canvas ref="pie-chart" :id="'pie-chart-' + index" style="display: block; width: 100%; height: 200px;" class="chartjs-render-monitor"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { authFetch, auth } from "@/libs/hxcore";
import Chart from 'chart.js';

export default {
    name: 'RekapHasilKecermatan',
    data() {
        return {
            info: {
                display_name: 'No Name'
            },
            datas : [],
        };
    },
    created: function () {
        // let ujian_code = this.$route.params.id;

        var is_finish = sessionStorage.getItem('is_finish');
        if (is_finish == 'reload') {
            sessionStorage.setItem('is_finish', 'sudah_reload');
            this.$router.go();
        }

        var info = auth.user();

        if(info)this.info = info;
    },
    mounted() {
        sessionStorage.removeItem('soals');
        sessionStorage.removeItem('soal_saat_ini');
        sessionStorage.removeItem('jawaban');
        var query_params = this.$route.query
        authFetch('/siswa/test/hasil_kecermatan/'+query_params.ujian_code).then((res) => {
            res.json().then((json) => {
                if(json.success)
                {
                    this.datas = json.data;
                    var ctx = {};
                    var chartKeaktifan = {};

                    var ctx_pie = {};
                    var chartPie = {};
                    var test = [];
                    setTimeout(() => { 
                        this.datas.map(function(item, index){
                            var benar = item.table_data.map(obj => obj.jawaban_benar);
                            var salah = item.table_data.map(obj => obj.jawaban_salah);
                            var tidak_terjawab = item.table_data.map(obj => obj.tidak_terjawab);
                            var benar_sum = item.table_data.reduce((total, obj) => total + parseInt(obj.jawaban_benar), 0);
                            var salah_sum = item.table_data.reduce((total, obj) => total + parseInt(obj.jawaban_salah), 0);
                            var tidak_terjawab_sum = item.table_data.reduce((total, obj) => total + parseInt(obj.tidak_terjawab), 0);
                            var terjawab_sum = item.table_data.reduce((total, obj) => total + parseInt(obj.terjawab), 0);
                            test.push({
                                'benar': benar_sum,
                                'salah': salah_sum,
                                'tidak_terjawab': tidak_terjawab_sum,
                                'terjawab': terjawab_sum,
                                'kecermatan_id' : item.id
                            })
                            ctx['ctx_'+index] = document.getElementById('grouped-chart-'+index).getContext('2d')
                            chartKeaktifan['chartKeaktifan_' + index] = new Chart(ctx['ctx_'+index], {
                                type: 'bar',
                                data: {
                                    labels: item.table_data.map(obj => 'Kolom ' + obj.col_index),
                                    datasets: [
                                        {
                                        label: "Benar",
                                        backgroundColor: "#29D679",
                                        data: benar,
                                        fill: false,
                                        },
                                        {
                                        label: "Salah",
                                        backgroundColor: "#F44747",
                                        data: salah,
                                        fill: false,
                                        },
                                        {
                                        label: "Tidak Terjawab",
                                        backgroundColor: "#F1D95C",
                                        data: tidak_terjawab,
                                        fill: false,
                                        }
                                    ]
                                },
                                options: {
                                    responsive: true,
                                    legend: {
                                        position: 'bottom',
                                    },
                                    title: {
                                        display: true,

                                    }
                                }
                            });

                            ctx_pie['ctx_pie_'+index] = document.getElementById('pie-chart-'+index).getContext('2d')
                            chartPie['chartPie_' + index] = new Chart(ctx_pie['ctx_pie_'+index], {
                                type: 'pie',
                                data: {
                                    labels: ['Benar','Salah','Tidak Terjawab'],
                                    datasets: [
                                        {
                                        label: "jumlah",
                                        backgroundColor: ["#29D679","#F44747","#F1D95C"],
                                        data: [benar_sum,salah_sum,tidak_terjawab_sum],
                                        fill: false,
                                        }
                                    ]
                                },
                                options: {
                                    responsive: true,
                                    legend: {
                                        position: 'bottom',
                                    },
                                    title: {
                                        display: true,

                                    }
                                }
                            });
                        })
                        var formData = {
                            'data' : JSON.stringify(test),
                        };
                        var payload = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&')
                        authFetch('/siswa/test/store_rekap_kecermatan', {
                            method: 'POST',
                            body: payload
                        })
                    }, 1000);
                }
            });
        });
    },
    methods:{
        getTotal(tableData, propertyName) {
            return tableData.reduce((total, item) => total + parseInt(item[propertyName] || 0), 0);
        }
    }
}
</script>

<style scoped>
    .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
        border-color: #6190E8 !important;
    }
    th{
        background-color: #6190E8 !important;
        color: white !important;
    }
    table {
        border-radius: 10px !important;
    }
    .card-penilaian {
        box-shadow: 0px 13px 13px 0px #00000012;
    }
</style>

